import React from "react"

import Label from "./Label"
import TextArea from "./TextArea"
import WrapperField from "./WrapperField"

function TextAreaField({ name, label, ...rest }) {
  return (
    <WrapperField>
      <Label htmlFor={name}>{label}</Label>
      <TextArea name={name} id={name} {...rest} />
    </WrapperField>
  )
}

export default TextAreaField
