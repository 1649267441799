import styled from "@emotion/styled"
import tw from "twin.macro"

const H1 = styled.h1`
  ${tw`text-primary text-4xl font-bold font-sans mb-2`}

  line-height: 3rem
`

export default H1
