import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import formReducer from "../utils/formReducer"
import encode from "../utils/encode"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import WrapperHeader from "../components/header/WrapperHeader"
import LogoLocalDeProva from "../components/header/LogoLocalDeProva"
import Nav from "../components/header/Nav"
import H1 from "../components/H1"
import Divider from "../components/Divider"
import InputField from "../components/InputField"
import TextAreaField from "../components/TextAreaField"
import ButtonPrimary from "../components/ButtonPrimary"
import Alert from "../components/Alert"

const CustomButtonPrimary = styled(ButtonPrimary)`
  ${tw`w-full md:w-auto px-10`}
`

const WrapperContent = styled.div`
  ${tw`lg:mt-20`}
`

const P = styled.div`
  ${tw`text-paragraph font-sans mb-5`}
`

const A = styled.a`
  ${tw`text-highlight underline font-sans outline-none focus:shadow-primary-opacity-60`}
`

const Form = styled.form`
  ${tw`w-full lg:w-3/5`}
`

const H2 = styled.h2`
  ${tw`text-primary text-2xl font-bold font-sans mb-2`}

  line-height: 3rem
`

const initialState = {
  isSending: false,
  isError: false,
  message: "",
  form: { nome: "", email: "", mensagem: "" },
}

export default function Sobre() {
  const [state, dispatch] = React.useReducer(formReducer, initialState)

  const handleChange = React.useCallback(e => {
    dispatch({ type: "setField", payload: { [e.target.name]: e.target.value } })
  }, [])

  const closeAlert = React.useCallback(() => {
    dispatch({ type: "clearMessage" })
  }, [])

  const handleSubmit = e => {
    e.preventDefault()

    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state.form,
      }),
    })
      .then(() => {
        dispatch({ type: "sendSuccess", payload: "Sua mensagem foi enviada." })
        dispatch({ type: "reset", payload: initialState.form })
      })
      .catch(() =>
        dispatch({
          type: "sendFailure",
          payload: "Não foi possível enviar sua mensagem. Tente mais tarde.",
        })
      )
  }

  return (
    <Layout>
      <SEO title="Sobre" />

      <WrapperHeader>
        <LogoLocalDeProva />
        <Nav />
      </WrapperHeader>

      <WrapperContent>
        <H1>Sobre</H1>
        <Divider />

        <P>
          Se você já teve que fazer uma prova de concurso em outra cidade, é
          provável que tenha ficado em dúvida sobre qual o melhor lugar para se
          hospedar. Bom, o melhor lugar é aquele perto do local da prova.
          Entretanto o local só é divulgado alguns dias da data da prova.
        </P>

        <P>
          Normalmente recorremos aos fóruns, grupos de Facebook, WhatsApp, etc
          para saber onde já foram realizadas provas de concurso em determinada
          cidade. Apesar de quase sempre ser possível encontrar ajuda, a
          informação que está ali acaba sendo perdida e as vezes difícil de ser
          recuperada. Já que sites de buscas pode não indexar de forma adequada
          o conteúdo em fóruns e redes sociais.
        </P>

        <P>
          Em razão disso, resolvi criar esse site para que esse tipo de
          informação seja mais facilmente encontrado e que possa ajudar os
          concurseiros que forem fazer prova em outra cidade a se planejarem
          melhor.
        </P>

        <H2>Contato</H2>

        <P>
          Fique a vontade para enviar uma sugestão, crítica ou apenas trocar uma
          ideia{" "}
          <span role="img" aria-hidden="true">
            😊
          </span>
          .
        </P>

        {state.message && (
          <Alert type={state.isError ? "error" : undefined} close={closeAlert}>
            {state.message}
          </Alert>
        )}

        <Form
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </p>

          <InputField
            name="nome"
            label="Nome"
            value={state.form.nome}
            onChange={handleChange}
            required
            minLength="3"
            disabled={state.isSending}
          />

          <InputField
            name="email"
            label="E-mail"
            type="email"
            value={state.form.email}
            onChange={handleChange}
            required
            disabled={state.isSending}
          />

          <TextAreaField
            name="mensagem"
            label="Mensagem"
            value={state.form.mensagem}
            onChange={handleChange}
            required
            rows="3"
            minLength="5"
            disabled={state.isSending}
          />

          <CustomButtonPrimary type="submit" disabled={state.isSending}>
            {state.isSending ? "Enviando..." : "Enviar mensagem"}
          </CustomButtonPrimary>
        </Form>

        <P style={{ marginTop: "5rem", fontSize: ".875rem" }}>
          Desenvolvido com{" "}
          <span role="img" aria-label="amor">
            ❤️
          </span>{" "}
          por <A href="https://twitter.com/RamonAhnert">@RamonAhnert</A>
        </P>
      </WrapperContent>
    </Layout>
  )
}
