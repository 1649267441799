export default function formReducer(state, action) {
  switch (action.type) {
    case "setField":
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload,
        },
      }
    case "send":
      return {
        ...state,
        isSending: true,
        isError: false,
        message: "",
      }
    case "sendSuccess":
      return {
        ...state,
        isSending: false,
        message: action.payload,
      }

    case "sendFailure":
      return {
        ...state,
        isSending: false,
        isError: true,
        message: action.payload,
      }

    case "reset":
      return {
        ...state,
        form: action.payload,
      }

    case "clearMessage":
      return {
        ...state,
        message: "",
      }

    default:
      throw new Error()
  }
}
