import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import CloseButton from "./CloseButton"

const WrapperAlert = styled.div`
  ${tw`w-full lg:w-3/5 bg-teal-100 border border-l-4 border-teal-500 rounded text-teal-900 px-4 py-3 shadow-md mb-6 relative flex justify-between`}

  border-color: ${({ type }) => (type === "error" ? "#a4100e" : "#0388b0")};
  background-color: ${({ type }) => (type === "error" ? "#ffe5e9" : "#dafffb")};
`

const TitleAlert = styled.p`
  ${tw`font-bold font-sans text-xl`}
`

const BodyAlert = styled.p`
  ${tw`mt-1 font-sans`}

  color: ${({ type }) => (type === "error" ? "#480b02" : "#00454e")};
`

export default function Alert({ type = "success", close, children }) {
  return (
    <WrapperAlert type={type}>
      <div>
        <TitleAlert>
          <span role="img" aria-label={type === "error" ? "Erro" : "Sucesso"}>
            {"success" === type && `🎉`}
            {"error" === type && `💥`}
          </span>
        </TitleAlert>
        <BodyAlert>{children}</BodyAlert>
      </div>
      <CloseButton onClick={close} />
    </WrapperAlert>
  )
}
