import styled from "@emotion/styled"
import tw from "twin.macro"

const Input = styled.input`
  ${tw`font-sans transition-colors duration-300 ease-in-out shadow appearance-none border rounded w-full py-3 px-3 text-primary leading-tight outline-none focus:outline-none focus:shadow-primary-opacity-60 text-lg `}

  &:disabled {
    ${tw`cursor-not-allowed`}
  }
`

export default Input
