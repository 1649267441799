import React from "react"

import Label from "./Label"
import Input from "./Input"
import WrapperField from "./WrapperField"

function InputField({ name, label, type = "text", ...rest }) {
  return (
    <WrapperField>
      <Label htmlFor={name}>{label}</Label>
      <Input name={name} id={name} type={type} autoComplete="off" {...rest} />
    </WrapperField>
  )
}

export default InputField
