import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

const SVG = styled.svg`
  ${tw`fill-current h-6 w-6 text-paragraph hover:text-color-teal-900`}
`

export default function CloseButton(props) {
  return (
    <SVG
      role="button"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}
    >
      <title>Fechar</title>
      <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
    </SVG>
  )
}
